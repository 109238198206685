export const CONST_AS_PILOT_ABILITIES: IASPilotAbility[] = [
{
    id: 1,
    ability: "Animal Mimicry",
    cost: 2,
    asce_page: 92,
    summary: ["Quadruped unit gains mobility bonus and ability to demoralize opponents"],
},
{
    id: 2,
    ability: "Antagonizer",
    cost: 3,
    asce_page: 92,
    summary: ["Unit can enrage an opponent for a brief period"],
},
{
    id: 3,
    ability: "Blood Stalker",
    cost: 2,
    asce_page: 93,
    summary: ["Unit may focus its attacks better on a preferred target until it is destroyed"],
},
{
    id: 4,
    ability: "Cluster Hitter",
    cost: 2,
    asce_page: 93,
    summary: ["Unit can deliver extra damage in an attack using missiles or flak weapons"],
},
{
    id: 5,
    ability: "Combat Intuition",
    cost: 3,
    asce_page: 93,
    summary: ["Unit may move and resolve fire before any other unit acts"],
},
{
    id: 6,
    ability: "Cross-Country",
    cost: 2,
    asce_page: 93,
    summary: ["Ground vehicle unit may enter some illegal terrain types, but at high Move cost"],
},
{
    id: 7,
    ability: "Demoralizer",
    cost: 3,
    asce_page: 93,
    summary: ["Unit can intimidate an opponent for a brief period"],
},
{
    id: 8,
    ability: "Dodge",
    cost: 2,
    asce_page: 95,
    summary: ["Unit can attempt to evade physical attacks"],
},
{
    id: 9,
    ability: "Dust-Off",
    cost: 2,
    asce_page: 95,
    summary: ["Enables airborne unit types to land or liftoff in non-clear terrain"],
},
{
    id: 10,
    ability: "Eagle's Eyes",
    cost: 2,
    asce_page: 95,
    summary: ["Unit gains (or augments) its ability to spot hidden units and avoid mines"],
},
{
    id: 11,
    ability: "Environmental Specialist",
    cost: 2,
    asce_page: 95,
    summary: ["Reduces movement and combat modifiers in a preferred environment"],
},
{
    id: 12,
    ability: "Fist Fire",
    cost: 2,
    asce_page: 96,
    summary: ["Unit delivers extra damage in physical attacks"],
},
{
    id: 13,
    ability: "Float Like a Butterfly",
    cost: 1,
    asce_page: 96,
    summary: ["Unit may force an opponent to reroll an attack with this unit as the target"],
},
{
    id: 14,
    ability: "Float Like a Butterfly",
    cost: 2,
    asce_page: 96,
    summary: ["Unit may force an opponent to reroll an attack with this unit as the target"],
},
{
    id: 15,
    ability: "Float Like a Butterfly",
    cost: 3,
    asce_page: 96,
    summary: ["Unit may force an opponent to reroll an attack with this unit as the target"],
},
{
    id: 16,
    ability: "Float Like a Butterfly",
    cost: 4,
    asce_page: 96,
    summary: ["Unit may force an opponent to reroll an attack with this unit as the target"],
},
{
    id: 17,
    ability: "Forward Observer",
    cost: 1,
    asce_page: 96,
    summary: ["Unit improves accuracy of indirect fire when used as a spotter"],
},
{
    id: 18,
    ability: "Golden Goose",
    cost: 3,
    asce_page: 96,
    summary: ["Improves accuracy for air-to-ground strafing, strike, and bombing attacks"],
},
{
    id: 19,
    ability: "Ground-Hugger",
    cost: 2,
    asce_page: 96,
    summary: ["Airborne unit may execute a double-strafe or double-strike air-to-ground attack"],
},
{
    id: 20,
    ability: "Headhunter",
    cost: 2,
    asce_page: 96,
    summary: ["Can automatically identify enemy command units"],
},
{
    id: 21,
    ability: "Heavy Lifter",
    cost: 1,
    asce_page: 97,
    summary: ["Enables increased carrying capacity with External Cargo rules"],
},
{
    id: 22,
    ability: "Hopper",
    cost: 1,
    asce_page: 97,
    summary: ["Unit may avoid being reduced below 1 inch of Move by MP Hits"],
},
{
    id: 23,
    ability: "Hot Dog",
    cost: 2,
    asce_page: 97,
    summary: ["Increases the Heat a unit can sustain before shutdown"],
},
{
    id: 24,
    ability: "Human TRO",
    cost: 1,
    asce_page: 97,
    summary: ["Unit can ignore the Concealing Unit Data rules vs. non-hidden opponents"],
},
{
    id: 25,
    ability: "Iron Will",
    cost: 1,
    asce_page: 97,
    summary: ["Unit can resist psychological attacks and receives a bonus during Morale checks"],
},
{
    id: 26,
    ability: "Jumping Jack",
    cost: 2,
    asce_page: 97,
    summary: ["Improves accuracy of any attack made when the unit uses jumping Move"],
},

{
    id: 27,
    ability: "Lucky",
    cost: 1,
    asce_page: 97,
    summary: ["Unit may reroll a limited number of failed attacks and Control Rolls per scenario"],
},
{
    id: 28,
    ability: "Lucky",
    cost: 2,
    asce_page: 97,
    summary: ["Unit may reroll a limited number of failed attacks and Control Rolls per scenario"],
},
{
    id: 29,
    ability: "Lucky",
    cost: 3,
    asce_page: 97,
    summary: ["Unit may reroll a limited number of failed attacks and Control Rolls per scenario"],
},
{
    id: 30,
    ability: "Lucky",
    cost: 4,
    asce_page: 97,
    summary: ["Unit may reroll a limited number of failed attacks and Control Rolls per scenario"],
},
{
    id: 31,
    ability: "Maneuvering Ace",
    cost: 2,
    asce_page: 97,
    summary: ["Reduces Move costs for woods/jungle terrain and aerospace atmospheric control"],
},
{
    id: 32,
    ability: "Marksman",
    cost: 2,
    asce_page: 97,
    summary: ["If unit attacks while stationary, may score extra critical after delivering 1 damage"],
},
{
    id: 33,
    ability: "Melee Master",
    cost: 2,
    asce_page: 98,
    summary: ["Unit increases its physical attack damage by half its Size (round up)"],
},
{
    id: 34,
    ability: "Melee Specialist",
    cost: 1,
    asce_page: 98,
    summary: ["Unit delivers physical attacks with greater accuracy"],
},
{
    id: 35,
    ability: "Multi-Tasker",
    cost: 2,
    asce_page: 98,
    summary: ["Unit can divide its weapon attack between two targets per turn"],
},
{
    id: 36,
    ability: "Natural Grace",
    cost: 3,
    asce_page: 98,
    summary: ["Unit gains 360-degree field of fire; reduces Move costs in ultra-heavy terrain"],
},
{
    id: 37,
    ability: "Oblique Artilleryman",
    cost: 1,
    asce_page: 98,
    summary: ["Improves accuracy and reduces scatter for all artillery weapon attacks"],
},
{
    id: 38,
    ability: "Oblique Attacker",
    cost: 1,
    asce_page: 98,
    summary: ["Improves accuracy for indirect fire, and enables indirect attacks without a spotter"],
},
{
    id: 39,
    ability: "Range Master",
    cost: 2,
    asce_page: 98,
    summary: ["Unit swaps normal range modifier for Medium, Long, or Extreme range with Short"],
},
{
    id: 40,
    ability: "Ride the Wash",
    cost: 4,
    asce_page: 98,
    summary: ["Unit reduces atmospheric combat modifiers; may execute special air-to-air attack"],
},
{
    id: 41,
    ability: "Sandblaster",
    cost: 2,
    asce_page: 99,
    summary: ["Unit improves accuracy and damage when only using AC and missile weapons"],
},
{
    id: 42,
    ability: "Shaky Stick",
    cost: 2,
    asce_page: 99,
    summary: ["Airborne unit is harder to hit from the ground during air-to-ground attacks"],
},
{
    id: 43,
    ability: "Sharpshooter",
    cost: 4,
    asce_page: 99,
    summary: ["If unit attacks while stationary, may score an extra critical after delivering full damage"],
},
{
    id: 44,
    ability: "Slugger",
    cost: 1,
    asce_page: 99,
    summary: ["'Mech unit can improvise its own melee weapons from suitable terrain"],
},
{
    id: 45,
    ability: "Sniper",
    cost: 3,
    asce_page: 99,
    summary: ["Unit reduces Medium, Long, and Extreme range modifiers by half."],
},
{
    id: 46,
    ability: "Speed Demon",
    cost: 2,
    asce_page: 99,
    summary: ["Unit can move faster than normal"],
},
{
    id: 47,
    ability: "Stand-Aside",
    cost: 1,
    asce_page: 99,
    summary: ["Unit can pass directly through enemy units at extra Move cost"],
},
{
    id: 48,
    ability: "Street Fighter",
    cost: 2,
    asce_page: 99,
    summary: ["Unit may pre-empt an attack against it by enemies in base contact"],
},
{
    id: 49,
    ability: "Sure-Footed",
    cost: 2,
    asce_page: 100,
    summary: ["Unit receives bonus movement on paved or ice terrain and ignores skidding"],
},
{
    id: 50,
    ability: "Swordsman",
    cost: 2,
    asce_page: 100,
    summary: ["Unit can deliver improved damage or critical hits when using MEL special"],
},
{
    id: 51,
    ability: "Tactical Genius",
    cost: 3,
    asce_page: 100,
    summary: ["Enables command unit to reroll Initiatives once every 2 turns"],
},
{
    id: 52,
    ability: "(Drag Racer)",
    cost: 3,
    asce_page: 100,
    summary: ["Ground vehicle unit gains extra speed on ice or pavement; avoids skidding better"],
},
{
    id: 53,
    ability: "(Forest Ranger)",
    cost: 3,
    asce_page: 100,
    summary: ["Unit moves more easily through (and gains extra cover from) woods and jungle"],
},
{
    id: 54,
    ability: "(Frogman)",
    cost: 3,
    asce_page: 100,
    summary: ["Unit moves more easily than others while fully submerged"],
},
{
    id: 55,
    ability: "(Mountaineer)",
    cost: 3,
    asce_page: 100,
    summary: ["Unit moves more easily through level changes and rough terrain types"],
},
{
    id: 56,
    ability: "(Nightwalker)",
    cost: 3,
    asce_page: 100,
    summary: ["Unit ignores combat modifiers for darkness"],
},
{
    id: 57,
    ability: "(Sea Monster)",
    cost: 3,
    asce_page: 101,
    summary: ["Unit moves more easily and ignores attack penalties in water terrain"],
},
{
    id: 58,
    ability: "(Swamp Beast)",
    cost: 3,
    asce_page: 101,
    summary: ["Unit moves more easily through mud and swamp terrain; ignores bog down in same"],
},
{
    id: 59,
    ability: "Weapon Specialist",
    cost: 3,
    asce_page: 101,
    summary: ["Unit can deliver a more accurate attack as long as it uses only half its firepower"],
},
{
    id: 60,
    ability: "Wind Walker",
    cost: 2,
    asce_page: 101,
    summary: ["Unit ignores atmospheric combat modifiers and gains a bonus to landing and liftoff"],
},
{
    id: 61,
    ability: "Zweihander",
    cost: 2,
    asce_page: 101,
    summary: ["’Mech unit delivers more damage in physical attacks"],
},
{
    id: 62,
    ability: "Light Horseman",
    cost: 2,
    asce_page: 101,
    summary: ["Beast-mounted infantry unit moves faster, even through difficult terrain"],
},
{
    id: 63,
    ability: "Heavy Horse",
    cost: 2,
    asce_page: 101,
    summary: ["Beast-mounted infantry unit can inflict extra damage at point-blank range"],
},
{
    id: 64,
    ability: "Foot Cavalry",
    cost: 1,
    asce_page: 101,
    summary: ["Foot-based infantry unit moves faster, even through difficult terrain"],
},
{
    id: 65,
    ability: "Urban Guerrilla",
    cost: 3,
    asce_page: 101,
    summary: ["Infantry unit is harder to attack in urban terrain, and may “spawn” support"],
},
{
    id: 66,
    ability: "Goshen Grad",
    cost: 2,
    asce_page: 0,
    summary: ["If closer and inbetween attacker and friendly unit, attacker must choose this pilot."]
}
]

export interface IASPilotAbility {
    id: number;
    ability: string;
    cost: number;
    summary: string[];
    asce_page: number;
}